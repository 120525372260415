import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import { Container, Col, Row } from 'react-grid-system';

import Carousel      from '../components/Carousel';
import Spacer        from '../components/Spacer';
import Heading       from '../components/Heading';
import Wrapper       from '../components/Wrapper';
import Separator     from '../components/Seperator';
import CallToAction  from '../modules/CallToAction';
import Expander      from '../components/Expander';
import DefaultLayout from '../layouts/Default';

class VerlobungsringePage extends React.Component {
  render() {
    return (
      <DefaultLayout location={this.props.location}>
        <Carousel>
          {this.props.data.headerImages.edges.map((image) => (
            <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
          ))}
        </Carousel>
        <Wrapper>
          <Spacer/>
          <Heading>Verlobungsringe</Heading>
          <h2 style={{ textAlign: 'center' }}>Glauben Sie, Ihre Freundin wartet ewig?</h2>
          <p>
            Der Verlobung gehen die Fragen aller Fragen voraus. Will ich sie? Will sie mich? Passen wir wirklich bis an
            unser Lebensende zusammen? ... Fragen über Fragen, die wir alle kennen, und dann kommt der große Moment:
            "Willst Du mich heiraten?" Es gibt kein emotionaleres Geschenk zu diesem Anlass als einen "unvergänglichen"
            Verlobungsdiamanten.
            Meistens in der Form eines Ringes, aber wir können uns auch andere Diamantschmuckstücke dafür vorstellen.
            Wir bieten ein ausgewogenes Angebot an Verlobungsschmuck und unsere sympathisch-seriöse Beratung. Wir freuen
            uns, wenn Sie uns Ihr Vertrauen schenken.
          </p>
          <h2 style={{ textAlign: 'center' }}>Mit dem Verlobungsring zeigt sie der ganzen Welt, dass sie "Ja" gesagt
            hat.</h2>
          <Spacer/>
          <Carousel>
            {this.props.data.galleryImages.edges.map((image) => (
              <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
            ))}
          </Carousel>
          <Spacer/>
          <Container fluid>
            <Row align='center' style={{ textAlign: 'center' }}>
              <Col md={6}>
                <b>Website: </b><a href='http://www.rubin-trauringe.de/verlobungsringe/' title='Rubin Verlobungsringe'
                                   target='_blank' rel='noopener noreferrer'>Rubin</a>
              </Col>
              <Col md={6}>
                <b>Website: </b><a href='http://saintmaurice.de/site/index/3' title='Saint Maurice Verlobungsringe'
                                   target='_blank' rel='noopener noreferrer'>Saint Maurice</a>
              </Col>
            </Row>
          </Container>
          <Separator/>
          <Container fluid>
            <Row>
              <Col md={4}>
                <img src='//mediaffects.com/customer/logo/gia-logo-240-80.png' alt='GIA'/>
              </Col>
              <Col md={8}>
                <p>
                  GIA ZERTIFIKAT BEI BRILLANTEN AB 0,30CT
                  Alle Verlobunsgringe von Rubin ab 0,30ct erhalten Sie bei uns mit einem GIA Zertifikat,
                  mit den Eigenschaften Exzellent und Fluoreszenz keine.
                </p>
                <Expander title='Was ist ein GIA-Zertifikat für Diamanten? - mehr erfahren ...' headerBgColor='#fff' textColor='#00000'>
                  <Spacer height={20}/>
                  <p>
                    Ein Zertifikat (auch Expertise genannt) ist ein Bewertungsgutachten, welches durch ein unabhängiges
                    anerkanntes gemmologisches Institut erstellt wird. Es ist die Geburtsurkunde des Diamanten und gibt
                    Auskunft über den Schliff, das Karat-Gewicht, die Farbe, Reinheit, Politur, Symmetrie, Fluoreszenz
                    und die äußeren Merkmale des Diamanten. Wir empfehlen Ihnen ausschließlich GIA-Zertifikate, weil
                    dieses Institut besonders strenge Maßstäbe bei der Bewertung der Diamanten anwendet und weil es
                    international anerkannt ist. Es ist weltweit das größte und älteste unabhängige Institut, dessen
                    Zertifikate sich durch Ihre Verlässlichkeit auszeichnen. Diamanten mit einem GIA-Zertifikat haben
                    daher die größte Wertbeständigkeit.
                    Alle von uns angebotenen Verlobungsringe der Marke Rubin ab einer Steingrösse von 0,30ct erhalten
                    Sie mit einem GIA-Zertifikat, mit den Eigenschaften Schliff + Symmetrie + Politur = exzellent. Alle
                    Brillanten weisen keine Fluoreszenz auf und sind mit einer Laserinschrift versehen. Dies erhöht die
                    Sicherheit, dass Diamant und Zertifikat wirklich zusammengehören.
                  </p>
                </Expander>

              </Col>
            </Row>
          </Container>
          <Separator/>
          <CallToAction/>
          <Spacer/>
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default VerlobungsringePage;

export const query = graphql`
  query EngagementringsPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/engagement-rings"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
    galleryImages: allFile(filter: {relativeDirectory: {eq: "engagement-rings"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
  }
`;
